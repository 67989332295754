@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: initial;
  padding: fn.spacing(6) 0;
}

.box-form {
  width: 100%;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .container {
    padding: fn.spacing(8) 0;
  }

  .title {
    font-size: 37px;
  }
}
